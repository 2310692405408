
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="department" class="col-12">
      <h5 class="mb-3">Department Details</h5>
      <div class="row">
        <div class="col-md-4 order-md-2">
          <div class="card overflow-hidden position-sticky" style="top: 90px;">
            <div class="bg-primary py-4">
            </div>
            <div class="card-body text-center">
              <router-link :to="`/users/${department.head.id}`" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                <img :src="absoluteUrl(department.head.image)" class="avatar-img rounded-circle border border-4 border-card" alt="head of department">
              </router-link>
              <div v-if="department.head">
                <p class="small text-muted mb-3">
                  Head of Department
                </p>
                <h3 class="card-title">
                  <router-link :to="`/users/${department.head.id}`">{{department.head.first_name}} {{department.head.last_name}}</router-link>
                </h3>
                <p class="mb-0">{{department.head.mobile}}</p>
                <p class="mb-0">{{department.head.email}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="">
                      <h4 class="lh-base mb-1">{{department.title}}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <!-- <div class="dropdown no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                     role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a @click="popupModalShow = true" class="dropdown-item"
                        href="javascript:void(0)" >
                          Update department </a>
                      </li>
                    </ul>
                  </div> -->
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-12">
                        <label class="form-label fw-bold">About department</label>
                        <div>{{department.description}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h6 class="card-header-title m-0">Case categories</h6>
                </div>
                <div class="card-body">
                  <ol class="ps-3">
                    <li v-for="(case_category, caIndex) in department.case_categories" :key="caIndex">
                      <h6>{{case_category.title}}</h6>
                      <p><small>{{case_category.description}}</small></p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h6 class="card-header-title m-0">Case Stages</h6>
                </div>
                <div class="card-body">
                  <ol class="ps-3">
                    <li v-for="(case_stage, csIndex) in department.case_stages" :key="csIndex">
                      <h6>{{case_stage.title}}</h6>
                      <p><small>{{case_stage.description}}</small></p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h6 class="card-header-title m-0">Case Duties</h6>
                </div>
                <div class="card-body">
                  <ol class="ps-3">
                    <li v-for="(case_duties, cdIndex) in department.case_duties" :key="cdIndex">
                      <h6>{{case_duties.title}}</h6>
                      <p><small>{{case_duties.description}}</small></p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "department",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      department: null
    }
  },
  methods: {
    updateItem(item){
      Object.assign(this.department, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get('/departments')
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.department = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

